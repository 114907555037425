import { Tooltip, Typography } from '@mui/material';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import styled from 'styled-components';

type EmailParameter = {
  [key: string]: string; // Dynamic key, value is always a string (human-readable label)
};

const emailParameters: EmailParameter[] = [
  { origin_city: 'Origin City' },
  { origin_state: 'Origin State' },
  { destination_city: 'Destination City' },
  { destination_state: 'Destination State' },
  { equipment_type: 'Equipment Type' },
  { total_price: 'Total Price' },
  { rate_per_mile: 'Rate Per Mile' },
  { pickup_time: 'Pickup Time' },
  { first_name: 'First Name' },
  { last_name: 'Last Name' },
  { company_name: 'Company Name' },
  { email: 'Email' },
  { phone_number: 'Phone Number' },
  { mc_number: 'MC Number' },
  { dot_number: 'DOT Number' },
];

export const EmailTemplateHelpTooltip = () => {
  return (
    <TooltipContent>
      <Tooltip
        title={
          <HelpContent>
            <Typography sx={{ margin: '5px 0' }} />
            <Typography sx={{ fontWeight: '800' }} variant="body2">
              Available parameters:
            </Typography>
            <Typography sx={{ margin: '10px 0' }} />
            {emailParameters.map((param, index) => {
              const paramName = Object.keys(param)[0]; // Get the parameter name
              const paramValue = param[paramName as keyof typeof param]; // Safely access the value with type assertion
              return (
                <Typography key={index} variant="body2">
                  {paramValue}: {`{${paramName}}`}
                </Typography>
              );
            })}
          </HelpContent>
        }
        placement="right"
      >
        <HelpIcon />
      </Tooltip>
    </TooltipContent>
  );
};

const TooltipContent = styled.div`
  position: absolute;
  right: 0;
  top: -10px;
`;

const HelpContent = styled.div`
  max-width: 300px;
  padding: 10px;
  background-color: #333;
  color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

const HelpIcon = styled(LiveHelpIcon)`
  font-size: 25px;
  color: ${({ theme }) => theme.detailsText};
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;
