import { Table, TableBody, TableContainer } from '@mui/material';
import { ILoad } from '../../Models/LoadModel';

import CustomTableHead from './CustomTableHead';
import { CollapsibleRow } from './CollapsibleRow';

type CollapsibleTableProps = {
  loadList: ILoad[];
};

//** Main MUI Table for Load boards **/
export const CollapsibleTable: React.FC<CollapsibleTableProps> = ({
  loadList,
}) => {
  return (
    <TableContainer>
      <Table aria-label="collapsible table">
        <CustomTableHead />
        <TableBody>
          {loadList.map((row) => (
            <CollapsibleRow key={row.external_id} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
