import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import * as S from './LeftbarLayout.styled';
import { SettingsModalWrapper } from '../../Components/Modal/SettingsModal/SettingsModalWrapper';

interface LeftbarLayoutProps {
  $isLeftbarOpen: boolean;
  $isLeftbarTransitioning: boolean;
  toggleLeftbar: () => void;
}

const LeftbarLayout: React.FC<LeftbarLayoutProps> = ({
  $isLeftbarOpen,
  $isLeftbarTransitioning,
  toggleLeftbar,
}) => {
  const [addonsEnabled] = useState<boolean>(false);
  const location = useLocation();

  const isChatPageActive = location.pathname === '/';

  return (
    <S.Container $isLeftbarOpen={$isLeftbarOpen}>
      <S.CenterContainer
        $isLeftbarOpen={$isLeftbarOpen}
        $isLeftbarTransitioning={$isLeftbarTransitioning}
      >
        <S.ArrowIconContainer onClick={toggleLeftbar}>
          {$isLeftbarOpen ? (
            <S.LeftArrow sx={{ fontSize: '50px' }} />
          ) : (
            <S.RightArrow sx={{ fontSize: '50px' }} />
          )}
        </S.ArrowIconContainer>
        <S.FlexContainer>
          <S.UpperDiv>
            {isChatPageActive ? (
              <S.AddonContainer
                to={'/scheduler'}
                $enabled={true}
                $isLeftbarOpen={$isLeftbarOpen}
                $isLeftbarTransitioning={$isLeftbarTransitioning}
              >
                <S.LeftBarParagraph $isActive={true} bold={true}>
                  LoadScout
                </S.LeftBarParagraph>
              </S.AddonContainer>
            ) : (
              <S.AddonContainer
                to={'/'}
                $enabled={true}
                $isLeftbarOpen={$isLeftbarOpen}
                $isLeftbarTransitioning={$isLeftbarTransitioning}
              >
                <S.LeftBarParagraph $isActive={true} bold={true}>
                  Chat Page
                </S.LeftBarParagraph>
              </S.AddonContainer>
            )}
            <S.AddonContainer
              to={'/'}
              $enabled={addonsEnabled}
              $isLeftbarOpen={$isLeftbarOpen}
              $isLeftbarTransitioning={$isLeftbarTransitioning}
            >
              <S.LeftBarParagraph $isActive={addonsEnabled}>
                AI Route Planner
              </S.LeftBarParagraph>
            </S.AddonContainer>
            <S.AddonContainer
              to={'/'}
              $enabled={addonsEnabled}
              $isLeftbarOpen={$isLeftbarOpen}
              $isLeftbarTransitioning={$isLeftbarTransitioning}
            >
              <S.LeftBarParagraph $isActive={addonsEnabled}>
                Analytics
              </S.LeftBarParagraph>
            </S.AddonContainer>
            <S.AddonContainer
              to={'/'}
              $enabled={addonsEnabled}
              $isLeftbarOpen={$isLeftbarOpen}
              $isLeftbarTransitioning={$isLeftbarTransitioning}
            >
              <S.LeftBarParagraph $isActive={addonsEnabled}>
                Chat History
              </S.LeftBarParagraph>
            </S.AddonContainer>
          </S.UpperDiv>
          <S.LowerDiv>
            <S.BottomAddonContainer
              target="_blank"
              rel="noopener noreferrer"
              to={'/help'}
              $enabled={true}
              $isLeftbarOpen={$isLeftbarOpen}
              $isLeftbarTransitioning={$isLeftbarTransitioning}
            >
              <S.LeftBarParagraph $isActive={true}>Help</S.LeftBarParagraph>
            </S.BottomAddonContainer>
            <S.SettingsContainer
              $isLeftbarOpen={$isLeftbarOpen}
              $isLeftbarTransitioning={$isLeftbarTransitioning}
            >
              <SettingsModalWrapper />
            </S.SettingsContainer>
          </S.LowerDiv>
        </S.FlexContainer>
      </S.CenterContainer>
    </S.Container>
  );
};

export default LeftbarLayout;
