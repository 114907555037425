import { isNumber, isString } from '../Utils/guardUtil';

export const checkCarrierNumbersVariables = (
  mcNumber1: any,
  mcNumber2: string | undefined,
  dotNumber: string | undefined
): { carrierNumber: string | null; isDot: boolean } => {
  if (isNumber(mcNumber1)) {
    return { carrierNumber: `${mcNumber1}`, isDot: false };
  }
  if (isString(mcNumber2)) {
    return { carrierNumber: mcNumber2, isDot: false };
  }
  if (isString(dotNumber)) {
    return { carrierNumber: dotNumber, isDot: true };
  }
  return { carrierNumber: null, isDot: false };
};
