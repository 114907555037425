import useAxios from '../useAxios/useAxios';
import toast from 'react-hot-toast';
import { getParsedCookie } from '../../Utils/cookieUtil';
import axios from 'axios';
import { addAuthInterceptor } from '../../Utils/addAuthInterceptor';

import {
  LOGIN_123LB_ENDPOINT,
  LOGIN_TRUCKSTOP_ENDPOINT,
  SEND_MESSAGE_ENDPOINT,
  CHECK_TRUCKSTOP_ENDPOINT,
  GET_CHAT_SETTINGS_ENDPOINT,
  UPDATE_CHAT_SETTINGS_ENDPOINT,
  TRUCKSTOP_RATINGS_ENDPOINT,
  CHECK_123LB_ENDPOINT,
  GET_MANIFOLD_PROVIDERS_ENDPOINT,
  CHECK_MANIFOLD_ENDPOINT,
} from '../../App/Axios/endpoints';
import { UserChatSettings } from '../../Context/ChatSettings/ChatSettingsContext';

const useChatApi = () => {
  const authAxios = useAxios(true); // Instance of axios with authentication

  // **Login with 123Loadboard**
  const login123LB = async (code: string) => {
    try {
      const url = `${LOGIN_123LB_ENDPOINT}?code=${code}`;
      const response = await authAxios.get(url);
      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
      return null;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  // **Login with Truckstop**
  const loginTruckstop = async (integrationId: string) => {
    try {
      const url = `${LOGIN_TRUCKSTOP_ENDPOINT}?ts_id=${integrationId}`;
      const response = await authAxios.post(url);
      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
      return null;
    } catch (e) {
      console.error('Error connecting to Truckstop', e);
      toast.error('Error connecting to Truckstop. Try again later');
      return null;
    }
  };

  // **Check Truckstop Integration**
  const checkTruckstopIntegration = async (): Promise<boolean> => {
    try {
      const url = `${CHECK_TRUCKSTOP_ENDPOINT}`;
      const response = await authAxios.post(url);
      if (response.status === 200 || response.status === 201) {
        return response.data.data;
      }
      return false;
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  // **Check 123LB Integration**
  const check123LBIntegration = async (): Promise<boolean> => {
    try {
      const url = `${CHECK_123LB_ENDPOINT}`;
      const response = await authAxios.get(url);
      if (response.status === 200 || response.status === 201) {
        return response.data.data;
      }
      return false;
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  // **Check Manifold Integration**
  const checkManifoldIntegration = async (): Promise<boolean> => {
    try {
      const url = `${CHECK_MANIFOLD_ENDPOINT}`;
      const response = await authAxios.get(url);
      if (response.status === 200 || response.status === 201) {
        return response.data.data;
      }
      return false;
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  // **Send Message**
  const sendMessage = async (text: string, boards: string[]) => {
    try {
      const url = `${SEND_MESSAGE_ENDPOINT}`;
      const response = await authAxios.post(url, {
        text: text,
        boards: boards,
      });
      return response;
    } catch (e: any) {
      console.log(e);
      return e.response;
    }
  };

  // **Fetch Chat Settings**
  const fetchChatSettings = async (
    setFetchSettingsError: React.Dispatch<React.SetStateAction<string | null>>
  ) => {
    setFetchSettingsError(null); // Reset error state before the request

    const jwtTokens = getParsedCookie('tokens');
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_SERVER_API_URL,
      headers: { Authorization: `Bearer ${jwtTokens?.access}` },
    });
    // Add the authorization interceptor
    addAuthInterceptor(axiosInstance);

    try {
      const response = await axiosInstance.get(GET_CHAT_SETTINGS_ENDPOINT);
      if (response.status === 200) {
        const fetchedSettings: UserChatSettings = response.data.data;
        return fetchedSettings;
      }
      setFetchSettingsError('Failed to fetch user settings.'); // Handle non-200 status codes
      return undefined;
    } catch (error) {
      console.error('Error fetching user settings:', error);
      setFetchSettingsError('Error fetching user settings.'); // Handle network or other errors
      return undefined;
    }
  };

  // **Update Chat Settings**
  const updateChatSettings = async (chatSettingsBody: UserChatSettings) => {
    const url = UPDATE_CHAT_SETTINGS_ENDPOINT;
    try {
      const response = await authAxios.patch(url, chatSettingsBody);

      if (response.status === 200) {
        console.log('Settings updated successfully:', response.data);
        toast.success('Settings updated');
        return response.data; // Return the updated data
      }
    } catch (error) {
      console.error('Error updating settings:', error);
      toast.error('Error updating user settings');
      throw error; // Rethrow the error so it can be handled externally
    }
  };

  // **TruckStop Rating**
  const truckStopRatingLogin = async (code: string, redirectUri: string) => {
    try {
      const requestBody = {
        code: code,
        redirect_uri: redirectUri,
      };

      const response = await authAxios.post(
        TRUCKSTOP_RATINGS_ENDPOINT,
        requestBody
      );

      if (response.status === 200 || response.status === 201) {
        console.log('Truckstop Rating Response:', response);
        return response.data;
      }

      return null;
    } catch (e) {
      console.error('Error in truckStopRating:', e);
      return null;
    }
  };

  // **Get Manifold Broker Providers**
  const getManifoldProviders = async () => {
    try {
      const url = `${GET_MANIFOLD_PROVIDERS_ENDPOINT}`;
      const response = await authAxios.get(url);
      return response;
    } catch (e: any) {
      console.log(e);
      return e.response;
    }
  };

  return {
    login123LB,
    loginTruckstop,
    sendMessage,
    checkTruckstopIntegration,
    check123LBIntegration,
    checkManifoldIntegration,
    fetchChatSettings,
    updateChatSettings,
    truckStopRatingLogin,
    getManifoldProviders,
  };
};

export default useChatApi;
