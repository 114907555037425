import styled from 'styled-components';

interface UserProfileTextAreaProps {
  label: string;
  name: string;
  value: string;
  handleInputChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string; // Optional for textarea placeholder
  disabled?: boolean; // Optional to disable the textarea
}

export const UserProfileTextArea: React.FC<UserProfileTextAreaProps> = ({
  label,
  name,
  value,
  handleInputChange,
  placeholder,
  disabled,
}) => {
  return (
    <FormGroup>
      <Label htmlFor={name}>{label}</Label>
      <Textarea
        name={name}
        id={name}
        value={value}
        onChange={handleInputChange}
        placeholder={placeholder || ''}
        disabled={disabled}
      />
    </FormGroup>
  );
};

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2px;
  margin-bottom: 10px;
  width: 100%;
`;

const Label = styled.label`
  margin-bottom: 8px;
  color: ${({ theme }) => theme.detailsText};
`;

const Textarea = styled.textarea`
  outline: none;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.settingsDisabledBg : theme.inputBgColor};
  resize: vertical; /* Allow resizing vertically */
  min-height: 250px;
  font-family: inherit; /* Match font with surrounding text */
`;
