import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { ILoad } from '../../Models/LoadModel';
import { Paragraph } from '../../App/Styles/Typography.styled';
import { CollapsibleTable } from '../Table/CollapsibleTable';

export const LazyTable = ({ loadList }: { loadList: ILoad[] }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const estimatedHeight = 80 + loadList.length * 90; // Header + rows height
  useEffect(() => {
    const currentRef = ref.current; // Capture the current ref value
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting); // Update isVisible based on visibility
      },
      { threshold: 0.01 }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <TableMessageContainer
      ref={ref}
      style={{
        minHeight: estimatedHeight,
      }}
    >
      {isVisible && <CollapsibleTable loadList={loadList} />}
    </TableMessageContainer>
  );
};

export const TableMessageContainer = styled.div`
  border-radius: 20px;
  background-image: ${(props) =>
    `linear-gradient(to bottom right, ${props.theme.secondary}, ${props.theme.primary})`};
  padding: 10px 10px 10px 10px;
  width: 100%;

  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  border-top: 1px solid ${(props) => `${props.theme.detailsText}30`};
  border-left: 1px solid ${(props) => `${props.theme.detailsText}30`};
  border-right: 1px solid ${(props) => `${props.theme.detailsText}30`};
  border-bottom: none;

  ${Paragraph} {
    color: ${(props) => props.theme.title};
    font-size: 12px;
    font-weight: 500;
  }
`;
