import useAxios from '../useAxios/useAxios';
import {
  LB123_ADD_TOKENS_ENDPOINT,
  LB123_DETAILS_ENDPOINT,
  LB123_LOADS_ENDPOINT,
  LB123_LOGIN_ENDPOINT,
  FMCSA_DETAILS_ENDPOINT,
  TRUCKSTOP_DETAILS_ENDPOINT,
  TRUCKSTOP_RATE_INSIGHTS_ENDPOINT,
  MULTIPLE_PROVIDER_DETAILS_ENDPOINT,
} from '../../App/Axios/endpoints';

const useLoadsApi = () => {
  // axios hook
  const axios = useAxios();
  const authAxios = useAxios(true);

  // login to Django server
  const loginLB123Api = async () => {
    try {
      const response = await axios.post(LB123_LOGIN_ENDPOINT);
      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
      return null;
    } catch (e) {
      return null;
    }
  };

  // Seemingly unused hook - Aniketh
  const addLB123TokensApi = async (tokenCode: string) => {
    try {
      const url = `${LB123_ADD_TOKENS_ENDPOINT}?code=${tokenCode}`;
      const response = await authAxios.get(url);
      if (response.status === 200 || response.status === 201) {
        console.log(response);
        return response.data;
      }
      return null;
    } catch (e) {
      return null;
    }
  };

  // **123LB GET API CALL**
  const getLB123LoadsApi = async (query: string) => {
    try {
      const response = await authAxios.get(
        `${LB123_LOADS_ENDPOINT}?query=${query}`
      );
      if (response.status === 200) {
        return response.data;
      }
      return null;
    } catch (e) {
      return null;
    }
  };

  // **123LB DETAILS**
  const getLB123DetailsAPI = async (load_id: string) => {
    try {
      const response = await authAxios.get(
        `${LB123_DETAILS_ENDPOINT}?load_id=${load_id}`
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (e: any) {
      if (e && e.response && e.response.data) {
        return e!.response?.data.data ? e.response.data.data.detail : null;
      }
      return null;
    }
  };
  // **TRUCKSTOP DETAILS**
  const getTruckstopDetailsAPI = async (load_id: string) => {
    try {
      const response = await authAxios.get(
        `${TRUCKSTOP_DETAILS_ENDPOINT}?load_id=${load_id}`
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (e: any) {
      if (e && e.response && e.response.data) {
        return e!.response?.data.data ? e.response.data.data.detail : null;
      }
      return null;
    }
  };

  // **FMCSA DETAILS**
  const getFMCSADetailsAPI = async (number: string | null, isDot: boolean) => {
    try {
      if (number === null) {
        console.log('Carrier Number is null');
        return null;
      }
      const url = !isDot
        ? `${FMCSA_DETAILS_ENDPOINT}?mc_number=${number}`
        : `${FMCSA_DETAILS_ENDPOINT}?dot_number=${number}`;
      const response = await authAxios.get(url);
      if (response.status === 200) {
        return response.data;
      }
    } catch (e: any) {
      if (e && e.response && e.response.data) {
        return e!.response?.data.data ? e.response.data.data.detail : null;
      }
      return null;
    }
  };

  // **TruckStop Rate Insights**
  const truckStopRateInsightDetails = async (loadId: string) => {
    try {
      const url = `${TRUCKSTOP_RATE_INSIGHTS_ENDPOINT}?load_id=${loadId}`;

      const response = await authAxios.get(url);

      if (response.status === 200 || response.status === 201) {
        console.log('TruckStop Rate Insights Response:', response.data);
        return response.data;
      }

      return null;
    } catch (e) {
      console.error('Error in truckStopRateInsightDetails:', e);
      return null;
    }
  };

  // **Multiple Provider Details**
  const getMultipleProviderDetailsAPI = async (
    board_id: number,
    external_id: string
  ) => {
    try {
      const url = `${MULTIPLE_PROVIDER_DETAILS_ENDPOINT}/${board_id}/${external_id}`;
      const response = await authAxios.get(url);
      if (response.status === 200) {
        return response.data;
      } else if (
        response.status === 400 &&
        response.data &&
        response.data.message === 'Try ReLogin to 123LoadBoard Provider.'
      ) {
        return { error: 'DISABLE_123_PLUGIN' };
      }
    } catch (e: any) {
      if (e && e.response) {
        if (
          e.response.status === 400 &&
          e.response.data &&
          e.response.data.message === 'Try ReLogin to 123LoadBoard Provider.'
        ) {
          return { error: 'DISABLE_123_PLUGIN' };
        }
        if (e.response.data && e.response.data.data) {
          return e.response.data.data.detail
            ? e.response.data.data.detail
            : null;
        }
      }
      return null;
    }
  };

  return {
    loginLB123Api,
    getLB123LoadsApi,
    addLB123TokensApi,
    getLB123DetailsAPI,
    getFMCSADetailsAPI,
    getTruckstopDetailsAPI,
    truckStopRateInsightDetails,
    getMultipleProviderDetailsAPI,
  };
};

export default useLoadsApi;
