import styled from 'styled-components';

import { numberWithCommas } from '../../Utils/numberUtil';
import { Paragraph } from '../../App/Styles/Typography.styled';
import { ProviderStringNamesEnum } from '../../App/Types/providerNames';
import { ILoad, ILoadDetail } from '../../Models/LoadModel';

interface MarketAnalysisProps {
  row: ILoad; // ILoad.board?: string | undefined
  detail: ILoadDetail | null;
}

export const MarketAnalysis: React.FC<MarketAnalysisProps> = ({
  row,
  detail,
}) => {
  const renderContent = () => {
    if (
      row.board === ProviderStringNamesEnum.TruckStop ||
      row.board === ProviderStringNamesEnum.Manifold
    ) {
      return (
        <>
          <MarketPriceHeader>Average Market Price</MarketPriceHeader>
          <InsightsCommingSoon>(Coming Soon)</InsightsCommingSoon>
        </>
      );
    }

    if (row.board === ProviderStringNamesEnum.LoadBoard123) {
      if (detail?.average_rate_for_mileage) {
        return (
          <>
            <MarketPriceHeader>Average Market Price</MarketPriceHeader>
            <MarketPriceText>
              ${numberWithCommas(detail.average_rate_for_mileage)} (MRPM $
              {numberWithCommas(detail.avg_rate_per_mile)})
            </MarketPriceText>
          </>
        );
      } else {
        return <Paragraph>No 123LoadBoard Analysis available</Paragraph>;
      }
    }

    return null;
  };

  return <MarketPriceContainer>{renderContent()}</MarketPriceContainer>;
};

const MarketPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 16rem;
  width: 100%;
  padding: 25px;
  margin-bottom: 1.5rem;
  margin-left: -0.5rem;
  margin-top: 0.5rem;
  background-color: ${({ theme }) => theme.secondary};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => `${theme.detailsText}30`};
`;

const MarketPriceHeader = styled(Paragraph)`
  font-size: 1rem !important;
  color: ${({ theme }) => theme.detailsText} !important;
  margin-bottom: 2px;
`;

const MarketPriceText = styled(Paragraph)`
  color: ${({ theme }) => theme.priceText} !important;
  font-size: 1.3rem !important;
  font-weight: bold !important;
`;

const InsightsCommingSoon = styled(Paragraph)`
  margin-bottom: 10px;
  color: ${({ theme }) => theme.detailsText} !important;
`;
