import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { UserProfileInputGroup } from './UserProfileInputGroup';
import UserProfileCard from '../../UserProfileCard/UserProfileCard';
import * as S from './userProfileForms.styled';
import { UserProfileTextArea } from './UserProfileTextArea';
import {
  useGetUserProfile,
  usePatchUserProfile,
} from '../../../Hooks/useApi/useAuthenticationApi';
import { IUser, IUserProfileUpdate } from '../../../Models/UserModel';
import { defautUserValues } from './defaultUserValues';
import { CustomCircularLoading } from '../../CustomCircularLoading/CustomCircularLoading';
import { EmailTemplateHelpTooltip } from '../../Tooltips/EmailTemplateHelpTooltip';

export const EmailTemplate = () => {
  //Api Hooks
  const { userProfileData, userProfileDataIsLoading, userProfileError } =
    useGetUserProfile();
  const { updateUserProfile, isLoadingProfileUpdate, isUpdateError } =
    usePatchUserProfile();
  // Navigate Hook
  const navigate = useNavigate();

  const [{ email_template, email_subject }, setFormValues] =
    useState<IUser>(defautUserValues);

  // Sync state with userData once it arrives for controlled data
  useEffect(() => {
    if (userProfileData) {
      setFormValues(userProfileData);
    }
  }, [userProfileData]);

  // Update formValues on input change
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const emailTemplatePayload = {
      ...userProfileData,
      email_subject,
      email_template,
    };
    updateUserProfile(emailTemplatePayload as IUserProfileUpdate);
  };

  if (userProfileDataIsLoading || isLoadingProfileUpdate) {
    return (
      <UserProfileCard>
        <CustomCircularLoading />;
      </UserProfileCard>
    );
  }

  return (
    <S.UserProfileFormContainer onSubmit={handleSubmit}>
      {userProfileError && (
        <S.UserProfileErrorSpan>
          Error fetching template data
        </S.UserProfileErrorSpan>
      )}
      {isUpdateError && (
        <S.UserProfileErrorSpan>
          Error updating template data
        </S.UserProfileErrorSpan>
      )}
      <UserProfileCard>
        <S.EmailTemplateHelpHeader>
          <EmailTemplateHelpTooltip />
        </S.EmailTemplateHelpHeader>
        <UserProfileInputGroup
          label="Subject"
          name="email_subject"
          value={email_subject}
          handleInputChange={handleInputChange}
        />
        <UserProfileTextArea
          label="Template content"
          name="email_template"
          value={email_template}
          handleInputChange={handleInputChange}
          disabled={false}
        />
        <S.UserProfileFormFooter>
          <S.UserProfileReturnButton onClick={() => navigate('/')}>
            Back to ChatPage
          </S.UserProfileReturnButton>
          <S.UserProfileButton type="submit">Save</S.UserProfileButton>
        </S.UserProfileFormFooter>
      </UserProfileCard>
    </S.UserProfileFormContainer>
  );
};
