import * as React from 'react';
import { useContext, useState, useMemo } from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Tabs from '@mui/material/Tabs';
import Skeleton from '@mui/material/Skeleton';
import { Button } from '@mui/material';
import Tab from '@mui/material/Tab';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import UpIcon from '@mui/icons-material/ExpandLess';
import DownIcon from '@mui/icons-material/ExpandMore';

import { Paragraph, Title } from '../../App/Styles/Typography.styled';
import { FlexColumn, FlexRow } from '../../App/Styles/Container.styled';
import { ILoad, ILoadDetail, ILoadFMCSADetail } from '../../Models/LoadModel';
import { formatDateTime, timeAgo } from '../../Utils/datetimeUtil';
import useLoadsApi from '../../Hooks/useApi/useLoadsApi';
import { IResponse } from '../../Models/BaseModel';
import EmailDraftModal from '../Modal/EmailDraftModal';
import { PortalContext } from '../../Context/Portal/PortalContext';
import { formatPhoneNumber, numberWithCommas } from '../../Utils/numberUtil';
import Map from '../Maps/Map';
import { FMCSATooltip } from '../Tooltips/FMCSATooltip';
import BoardIcon from './GetBoardIcon';
import { checkCarrierNumbersVariables } from '../../Utils/chatPageHelpers';
import { MarketAnalysis } from './MarketAnalysis';
import { PluginContext } from '../../Context/Plugin/PluginContext';

//****** MAIN MUI ROW COMPONENT *******//
export const CollapsibleRow: React.FC<{ row: ILoad }> = ({ row }) => {
  const portalContext = useContext(PortalContext);
  const { getFMCSADetailsAPI, getMultipleProviderDetailsAPI } = useLoadsApi();
  const [open, setOpen] = useState(false);
  const [moreInfoOpen, setMoreInfoOpen] = useState(false);
  const [value, setValue] = useState<string>('one');
  const [loading, setLoading] = useState(false);
  const pluginContext = useContext(PluginContext);
  const [detailsCache, setDetailsCache] = useState<{
    [key: string]: {
      detail: ILoadDetail | null;
      fmcsaDetail: ILoadFMCSADetail | null;
      predictionData?: {
        prediction_rate: number;
        lower_rate: number;
        upper_rate: number;
      } | null;
    };
  }>({});
  const url_123LoadBoard =
    process.env.REACT_APP_123LB_LOADS_URL + row.external_id;

  const memoizedOrigin = useMemo(
    () => [Number(row.origin_latitude), Number(row.origin_longitude)],
    [row.origin_latitude, row.origin_longitude]
  );
  const memoizedDestination = useMemo(
    () => [Number(row.destination_latitude), Number(row.destination_longitude)],
    [row.destination_latitude, row.destination_longitude]
  );

  const onRowClickListener = async (load_id: string) => {
    setOpen(!open);
    if (!open && !detailsCache[load_id]) {
      setLoading(true);

      // Fetch load details from the appropriate API depending on the source
      const detailData = await getMultipleProviderDetailsAPI(
        row.board_id,
        row.external_id
      );

      //* extra error handling *//
      if (!detailData) {
        console.log('Failed to fetch detail data for load', load_id);
        setLoading(false);
        return;
      } else if (detailData.error === 'DISABLE_123_PLUGIN') {
        pluginContext?.setLB123Plugin(false);
        console.warn('123LoadBoard Plugin will be disabled');
        setLoading(false);
        return; // Stop further execution
      }

      const data = detailData.data as ILoadDetail;

      const { carrierNumber, isDot } = checkCarrierNumbersVariables(
        row?.mc_number,
        data?.mc_number,
        data?.dot_number
      );

      // Fetch FMCSA (Federal Motor Carrier Safety Administration) details using the carrier number and type
      const fmcsa_data = (await getFMCSADetailsAPI(
        carrierNumber,
        isDot
      )) as IResponse<ILoadFMCSADetail>;
      // At this point, we have the details and FMCSA data for this load, and we can now cache it.

      setDetailsCache((prevCache) => ({
        ...prevCache,
        [load_id]: {
          detail: data || null,
          fmcsaDetail: fmcsa_data?.data || null,
          predictionData: null, // Initialize without prediction data
        },
      }));
      setLoading(false);
    }
  };

  const onSendEmailButtonClickListener = (load: ILoad, mail?: string) => {
    load.email = mail;
    portalContext?.setModal(<EmailDraftModal load={load} />);
    portalContext?.setIsOpen(true);
  };

  const detail = detailsCache[row.external_id]?.detail;
  const fmcsaDetail = detailsCache[row.external_id]?.fmcsaDetail;
  // const predictionData = detailsCache[row.external_id]?.predictionData; // Retrieve prediction data

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    if (newValue === 'two' && !detailsCache[row.external_id]?.predictionData) {
      // fetchPredictionRates(row.external_id); // Fetch prediction rates only when "value two" is selected
    }
  };

  const formatData = (key: string, value: any) => {
    switch (key) {
      case 'allowedToOperate':
        return value === 'Y' ? (
          <GreenText>Yes</GreenText>
        ) : (
          <RedText>No</RedText>
        );
      case 'brokerAuthorityStatus':
        return value === 'A' ? (
          <GreenText>Active</GreenText>
        ) : (
          <RedText>Inactive</RedText>
        );
      case 'safetyRating':
        return value === 'Satisfactory' ? (
          <GreenText>Satisfactory</GreenText>
        ) : value === 'Unsatisfactory' ? (
          <RedText>Unsatisfactory</RedText>
        ) : (
          'Unavailable'
        );
      case 'statusCode':
        return value === 'A' ? (
          <GreenText>Active</GreenText>
        ) : (
          <RedText>Inactive</RedText>
        );
      case 'bipdInsuranceOnFile':
      case 'bondInsuranceOnFile':
      case 'cargoInsuranceOnFile':
        return value === '0'
          ? 'Data Not Available'
          : `$${numberWithCommas(Number(value) * 10000)}`;
      case 'totalPowerUnits':
        return Number(value) > 2 ? (
          <GreenText>More than 2</GreenText>
        ) : (
          <RedText>Less than 2</RedText>
        );
      default:
        return value;
    }
  };

  const handleMoreInfoClick = () => {
    setMoreInfoOpen(!moreInfoOpen);
  };

  return (
    <React.Fragment>
      <TableRow>
        <StyledTableCell>
          <CustomIconButton
            aria-label="expand row"
            size="small"
            onClick={() => onRowClickListener(row.external_id)}
            color="success"
          >
            {open ? <UpIcon /> : <DownIcon />}
          </CustomIconButton>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row" align="center">
          <StyledParagraph>{timeAgo(row.published_at)}</StyledParagraph>
        </StyledTableCell>
        <StyledTableCell align="center">
          <FlexColumn justify="center" alignItems="center" gap="0.3rem">
            <StyledParagraph>{row.origin_city}</StyledParagraph>
            <StyledTitle>{row.origin_state}</StyledTitle>
          </FlexColumn>
        </StyledTableCell>
        <StyledTableCell align="center">
          <FlexColumn justify="center" alignItems="center" gap="0.3rem">
            <StyledParagraph>{row.destination_city}</StyledParagraph>
            <StyledTitle>{row.destination_state}</StyledTitle>
          </FlexColumn>
        </StyledTableCell>
        <StyledTableCell align="center">
          <FlexRow justify="center">
            <StyledParagraph>{row.company_name}</StyledParagraph>
          </FlexRow>
        </StyledTableCell>
        <StyledTableCell align="center">
          <FlexColumn justify="center" alignItems="center" gap="0.3rem">
            <StyledParagraph>
              {row.weight ? `${numberWithCommas(row.weight)} lbs` : null}
            </StyledParagraph>
          </FlexColumn>
        </StyledTableCell>
        <StyledTableCell align="center">
          <FlexRow justify="center">
            <StyledParagraph>{row.equipment_type}</StyledParagraph>
          </FlexRow>
        </StyledTableCell>
        <StyledTableCell align="center">
          <FlexColumn justify="center" alignItems="center" gap="0.3rem">
            <PriceTitle color="#8BFFCF">
              ${numberWithCommas(row.total_price)}
            </PriceTitle>
            <StyledParagraph>
              RPM: ${numberWithCommas(row.rate_per_mile)}
            </StyledParagraph>
          </FlexColumn>
        </StyledTableCell>
        <StyledTableCell align="center">
          <FlexRow justify="center">
            <StyledParagraph>{numberWithCommas(row.mileage)}</StyledParagraph>
          </FlexRow>
        </StyledTableCell>
        <StyledTableCell align="center">
          <StyledParagraph>{formatDateTime(row.pickup!)}</StyledParagraph>
        </StyledTableCell>
        <StyledTableCell align="center">
          <StyledParagraph>
            <BoardIcon board={row.board} url_123LoadBoard={url_123LoadBoard} />
          </StyledParagraph>
        </StyledTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={12}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <ContentArea>
              <div>
                <StyledTabs value={value} onChange={handleChange}>
                  <StyledTab value="one" label="Details" />
                  <StyledTab value="two" label="Market Analysis" />
                  <StyledTab value="three" label="Background Screening" />
                </StyledTabs>
                <Box sx={{ margin: 1 }}>
                  {loading ? (
                    <DetailsContainer>
                      <DetailFlexColumn justify="flex-start" gap="0.5rem">
                        <BrightSkeleton
                          animation="wave"
                          variant="text"
                          width="80%"
                          height={30}
                        />
                        <BrightSkeleton
                          animation="wave"
                          variant="text"
                          width="60%"
                          height={30}
                        />
                        <BrightSkeleton
                          animation="wave"
                          variant="text"
                          width="40%"
                          height={30}
                        />
                        <BrightSkeleton
                          animation="wave"
                          variant="text"
                          width="50%"
                          height={30}
                        />
                        <BrightSkeleton
                          animation="wave"
                          variant="text"
                          width="70%"
                          height={30}
                        />
                        <BrightSkeleton
                          animation="wave"
                          variant="text"
                          width="90%"
                          height={30}
                        />
                      </DetailFlexColumn>
                    </DetailsContainer>
                  ) : (
                    <>
                      <DetailsContainer>
                        {value === 'one' && (
                          <DetailFlexColumn justify="flex-start" gap="0.5rem">
                            <DetailItem>
                              <DetailsParagraphHeader>
                                Company Name:
                              </DetailsParagraphHeader>
                              <DetailsParagraph>
                                {row?.company_name}
                              </DetailsParagraph>
                            </DetailItem>
                            <DetailItem>
                              <DetailsParagraphHeader>
                                Contact Name:
                              </DetailsParagraphHeader>
                              <DetailsParagraph>
                                {detail?.contact_name}
                              </DetailsParagraph>
                            </DetailItem>
                            <DetailItem>
                              <DetailsParagraphHeader>
                                Phone:
                              </DetailsParagraphHeader>
                              <DetailsParagraph>
                                {formatPhoneNumber(detail?.phone)}
                              </DetailsParagraph>
                            </DetailItem>
                            <DetailItem>
                              <DetailsParagraphHeader>
                                Email:
                              </DetailsParagraphHeader>
                              <DetailsParagraph>
                                {detail?.email}
                              </DetailsParagraph>
                            </DetailItem>
                            <DetailItem>
                              <DetailsParagraphHeader>
                                Description:
                              </DetailsParagraphHeader>
                              <DetailsParagraph>
                                {detail?.description}
                              </DetailsParagraph>
                            </DetailItem>
                            <DetailItem>
                              <DetailsParagraphHeader>
                                Notes:
                              </DetailsParagraphHeader>
                              <DetailsParagraph>
                                {detail?.notes}
                              </DetailsParagraph>
                            </DetailItem>
                          </DetailFlexColumn>
                        )}
                        {value === 'two' && (
                          <MarketAnalysis row={row} detail={detail} />
                        )}
                        {value === 'three' && (
                          <DetailFlexColumn justify="flex-start" gap="0.5rem">
                            {detail?.mc_number || fmcsaDetail?.dotNumber ? (
                              <>
                                <FMCSATooltipWrapper>
                                  <FMCSATooltip />
                                </FMCSATooltipWrapper>
                                <DetailItem>
                                  <DetailsParagraphHeader>
                                    DOT:
                                  </DetailsParagraphHeader>
                                  <DetailsParagraph>
                                    {fmcsaDetail?.dotNumber}
                                  </DetailsParagraph>
                                </DetailItem>
                                <DetailItem>
                                  <DetailsParagraphHeader>
                                    MC:
                                  </DetailsParagraphHeader>
                                  <DetailsParagraph>
                                    {detail?.mc_number}
                                  </DetailsParagraph>
                                </DetailItem>
                                <DetailItem>
                                  <DetailsParagraphHeader>
                                    Legal Name:
                                  </DetailsParagraphHeader>
                                  <DetailsParagraph>
                                    {fmcsaDetail?.legalName}
                                  </DetailsParagraph>
                                </DetailItem>
                                <DetailItem>
                                  <DetailsParagraphHeader>
                                    Physical Address:
                                  </DetailsParagraphHeader>
                                  <DetailsParagraph>
                                    {[
                                      fmcsaDetail?.phyStreet,
                                      fmcsaDetail?.phyCity,
                                      fmcsaDetail?.phyState,
                                      fmcsaDetail?.phyZipcode,
                                      fmcsaDetail?.phyCountry,
                                    ]
                                      .filter(Boolean)
                                      .join(', ')}
                                  </DetailsParagraph>
                                </DetailItem>
                                <DetailItem>
                                  <DetailsParagraphHeader>
                                    Allowed to Operate:
                                  </DetailsParagraphHeader>
                                  <DetailsParagraph>
                                    {formatData(
                                      'allowedToOperate',
                                      fmcsaDetail?.allowedToOperate
                                    )}
                                  </DetailsParagraph>
                                </DetailItem>
                                <DetailItem>
                                  <DetailsParagraphHeader>
                                    Status Code:
                                  </DetailsParagraphHeader>
                                  <DetailsParagraph>
                                    {formatData(
                                      'statusCode',
                                      fmcsaDetail?.statusCode
                                    )}
                                  </DetailsParagraph>
                                </DetailItem>
                                <DetailsParagraphHeader>
                                  Interstate Authority <br />
                                  (Broker Authority Status):{' '}
                                  {formatData(
                                    'brokerAuthorityStatus',
                                    fmcsaDetail?.brokerAuthorityStatus
                                  )}
                                </DetailsParagraphHeader>
                                <DetailItem>
                                  <DetailsParagraphHeader>
                                    BIPD Insurance:
                                  </DetailsParagraphHeader>
                                  <DetailsParagraph>
                                    {formatData(
                                      'bondInsuranceOnFile',
                                      fmcsaDetail?.bondInsuranceOnFile
                                    )}
                                  </DetailsParagraph>
                                </DetailItem>
                                <DetailItem>
                                  <DetailsParagraphHeader>
                                    Cargo Insurance:
                                  </DetailsParagraphHeader>
                                  <DetailsParagraph>
                                    {formatData(
                                      'cargoInsuranceOnFile',
                                      fmcsaDetail?.cargoInsuranceOnFile
                                    )}
                                  </DetailsParagraph>
                                </DetailItem>
                                <DetailItem>
                                  <DetailsParagraphHeader>
                                    Total Inspection in last 24 months:
                                  </DetailsParagraphHeader>
                                  <DetailsParagraph>
                                    {fmcsaDetail?.vehicleInsp}
                                  </DetailsParagraph>
                                </DetailItem>
                                <MoreInfoText onClick={handleMoreInfoClick}>
                                  {moreInfoOpen ? 'Show Less' : 'More Info'}
                                </MoreInfoText>{' '}
                              </>
                            ) : (
                              <BackgroundScreeningFallBack>
                                <BackgroundScreeningText>
                                  MC/DOT number missing
                                </BackgroundScreeningText>
                              </BackgroundScreeningFallBack>
                            )}
                            {moreInfoOpen && (
                              <BlurBackground>
                                <DetailFlexColumnMoreInfo
                                  justify="flex-start"
                                  gap="0.5rem"
                                >
                                  <DetailItem>
                                    <DetailsParagraphHeader>
                                      Out of Service Date:
                                    </DetailsParagraphHeader>
                                    <DetailsParagraph>
                                      {fmcsaDetail?.oosDate || 'None'}
                                    </DetailsParagraph>
                                  </DetailItem>
                                  <DetailItem>
                                    <DetailsParagraphHeader>
                                      Safety Rating:
                                    </DetailsParagraphHeader>
                                    <DetailsParagraph>
                                      {formatData(
                                        'safetyRating',
                                        fmcsaDetail?.safetyRating
                                      )}
                                    </DetailsParagraph>
                                  </DetailItem>
                                  <DetailItem>
                                    <DetailsParagraphHeader>
                                      Total Power Units:
                                    </DetailsParagraphHeader>
                                    <DetailsParagraph>
                                      {fmcsaDetail?.totalPowerUnits}
                                    </DetailsParagraph>
                                  </DetailItem>
                                  <DetailItem>
                                    <DetailsParagraphHeader>
                                      Major crashes:
                                    </DetailsParagraphHeader>
                                    <DetailsParagraph>
                                      {fmcsaDetail?.crashTotal === '0' ? (
                                        <GreenText>
                                          {fmcsaDetail?.crashTotal}
                                        </GreenText>
                                      ) : (
                                        <RedText>
                                          {fmcsaDetail?.crashTotal}
                                        </RedText>
                                      )}
                                    </DetailsParagraph>
                                  </DetailItem>
                                  <DetailItem>
                                    <DetailsParagraphHeader>
                                      Total drivers:
                                    </DetailsParagraphHeader>
                                    <DetailsParagraph>
                                      {fmcsaDetail?.totalDrivers}
                                    </DetailsParagraph>
                                  </DetailItem>
                                  <DetailItem>
                                    <DetailsParagraphHeader>
                                      Towaway crash:
                                    </DetailsParagraphHeader>
                                    <DetailsParagraph>
                                      {fmcsaDetail?.towawayCrash}
                                    </DetailsParagraph>
                                  </DetailItem>
                                  <DetailItem>
                                    <DetailsParagraphHeader>
                                      DBA name:
                                    </DetailsParagraphHeader>
                                    <DetailsParagraph>
                                      {fmcsaDetail?.dbaName}
                                    </DetailsParagraph>
                                  </DetailItem>
                                </DetailFlexColumnMoreInfo>
                              </BlurBackground>
                            )}
                          </DetailFlexColumn>
                        )}
                      </DetailsContainer>
                    </>
                  )}
                  <FlexRow
                    justify="flex-start"
                    gap="0.5rem"
                    marginBottom="1.5rem"
                  >
                    <MuiEmailButton
                      onClick={() =>
                        onSendEmailButtonClickListener(row, detail?.email)
                      }
                    >
                      Send email
                    </MuiEmailButton>
                    <DialButton>Dial</DialButton>
                    {detail?.external_url && (
                      <BookLoadLink
                        href={detail.external_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        BOOK LOAD
                      </BookLoadLink>
                    )}
                  </FlexRow>
                </Box>
              </div>
              {loading ? (
                <MapLoadingArea>
                  <BrightSkeleton
                    animation="wave"
                    variant="rectangular"
                    width="100%"
                    height="100%"
                  />
                </MapLoadingArea>
              ) : (
                <MapArea>
                  <Map
                    origin={memoizedOrigin as [number, number]}
                    destination={memoizedDestination as [number, number]}
                    originCity={row?.origin_city}
                    originState={row?.origin_state}
                    destinationCity={row?.destination_city}
                    destinationState={row?.destination_state}
                  />
                </MapArea>
              )}
            </ContentArea>
          </Collapse>
        </StyledTableCell>
      </TableRow>
    </React.Fragment>
  );
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: '16px 4px',
    background: 'transparent',
    color: theme.text,
    borderBottom: `1px solid grey !important`,
    borderTop: 'none !important',
    fontWeight: 'bold',
    borderColor: theme.secondary,
    width: '10%', // Ensure consistent width
  },
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: 'transparent',
  width: '140%',
  color: theme.title,
  '.MuiTabs-indicator': {
    backgroundColor: theme.title,
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 'bold',
  color: theme.text,
  '&.MuiTab-root': {
    fontSize: '.73rem',
    color: theme.text,
  },
  '&&.Mui-selected, &&.Mui-selected:hover': {
    color: theme.title,
    backgroundColor: theme.secondary,
  },
  '&:hover': {
    backgroundColor: theme.hover,
    color: theme.title,
    opacity: 1,
  },
  '&&.Mui-focusVisible': {
    backgroundColor: theme.hover,
    color: theme.title,
  },
}));

const MuiEmailButton = styled(Button)`
  text-transform: none;
  font-size: 12px !important;
  background-color: ${({ theme }) => theme.title} !important;
  color: ${({ theme }) => theme.primary} !important;

  disabled {
    background-color: ${({ theme }) => theme.hover} !important;
    // color: ${({ theme }) => theme.text};
    cursor: not-allowed;
  }
  &:hover {
    background-color: ${({ theme }) => theme.hoverAlternativeColor} !important;
  }
`;

const BookLoadLink = styled.a`
  font-size: 12px !important;
  font-weight: 550 !important;
  background-color: ${({ theme }) => theme.title} !important;
  color: ${({ theme }) => theme.primary} !important;
  text-decoration: none;
  padding: 10px;
  border-radius: 4px;
  &:hover {
    background-color: ${({ theme }) => theme.hoverAlternativeColor} !important;
  }
`;

const DialButton = styled(Button)`
  text-transform: none;
  font-size: 12px !important;
  font-weight: 500;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.title} !important;
  color: ${({ theme }) => theme.title} !important;
  background-color: ${({ theme }) => theme.hover};
  padding: 8px 24px;
  cursor: not-allowed;
  transition: none;
  opacity: 0.5;
  &:hover,
  &:focus,
  &:active {
    background-color: ${({ theme }) => theme.hover};
    color: ${({ theme }) => theme.title} !important;
  }
  pointer-events: none;
`;

const CustomIconButton = styled(IconButton)`
  color: ${({ theme }) => theme.alternativeWhite} !important;
`;

const BrightSkeleton = styled(Skeleton)`
  .MuiSkeleton-root {
    background-color: rgba(255, 255, 255, 0.3);
  }
`;

const DetailsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
`;

const DetailFlexColumn = styled(FlexColumn)`
  display: flex;
  min-height: 16rem;
  width: 100%;
  padding: 25px;
  margin-bottom: 1.5rem;
  margin-left: -0.5rem;
  margin-top: 0.5rem;
  background-color: ${({ theme }) => theme.secondary};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => `${theme.detailsText}30`};
`;

const DetailItem = styled.div`
  display: flex;
  align-items: flex-start; /* Ensures top alignment of key and value */
  gap: 0.5rem;
  margin-bottom: 0.5rem;
`;

const DetailsParagraph = styled(Paragraph)`
  color: ${({ theme }) => theme.detailsText} !important;
  flex: 1; /* Allows the value to take up remaining space */
  word-break: break-word; /* Ensures long text wraps within its container */
`;

const DetailsParagraphHeader = styled(Paragraph)`
  color: ${({ theme }) => theme.title};
  white-space: nowrap; /* Prevents the key from wrapping */
  flex-shrink: 0; /* Prevents the key from shrinking */
`;

const StyledParagraph = styled(Paragraph)`
  color: ${({ theme }) => theme.detailsText} !important;
`;

const MarketPriceText = styled(Paragraph)`
  color: ${({ theme }) => theme.priceText} !important;
  font-size: 1.3rem !important;
  font-weight: bold !important;
`;

const BackgroundScreeningFallBack = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BackgroundScreeningText = styled(MarketPriceText)`
  font-size: 1rem !important;
  color: ${({ theme }) => theme.detailsText} !important;
  font-weight: 200 !important;
`;

const StyledTitle = styled(Title)`
  color: ${({ theme }) => theme.text};
`;

const PriceTitle = styled(Title)`
  color: ${({ theme }) => theme.priceText};
`;

const ContentArea = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.1fr;
  width: 100%;
  position: relative; /* Added to ensure absolute positioning works within this container */
`;

const MapArea = styled.div`
  flex: 1;
  height: 300px;
  width: 50%;
  margin-top: 4.1rem;
  position: absolute; /* Absolutely positioned within the parent container */
  top: 0;
  left: 50%;
  right: 0;
  bottom: 0;
  z-index: -0;
`;

const MapLoadingArea = styled.div`
  height: 300px;
  width: 100%;
  margin-top: 3.5rem;
  background-color: ${({ theme }) => theme.secondary};
  border-radius: 10px;
`;

const GreenText = styled.span`
  color: ${({ theme }) => theme.priceText};
`;

const RedText = styled.span`
  color: #cc0000;
`;

const MoreInfoText = styled.span`
  color: #007bff; /* Bright blue color */
  cursor: pointer;
  text-decoration: underline;
  font-size: 0.875rem;
  font-weight: 500;
  margin-top: 1rem;
  display: inline-block;
  transition: color 0.3s ease; /* Smooth transition for color change */
`;

const DetailFlexColumnMoreInfo = styled(FlexColumn)`
  display: flex;
  min-height: 16rem;
  width: 100%;
  padding: 25px;
  margin-bottom: 1.5rem;
  margin-left: -0.5rem;
  margin-top: 0.5rem;
  border-radius: 8px;
`;

const BlurBackground = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  height: 300px;
  margin-top: 4.1rem;
  width: 50%;
  backdrop-filter: blur(5px); /* Blurs the background */
  background-color: ${({ theme }) => {
    const hex = theme.primary.replace('#', '');
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `rgba(${r}, ${g}, ${b}, 0.85)`;
  }};
  z-index: 1; /* Ensures it appears above other content */
  padding: 1rem;
`;

const FMCSATooltipWrapper = styled.div`
  position: relative;
`;
