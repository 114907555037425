import React from 'react';
import { AuthenticationContextProvider } from '../Auth/AuthContext';
import { ChildrenProps } from '../index';
import { ThemeContextProvider } from '../Theme/ThemeContext';
import { LoadingContextProvider } from '../Loading/LoadingContext';
import { PluginContextProvider } from '../Plugin/PluginContext';
import { PortalContextProvider } from '../Portal/PortalContext';
import { SettingsProvider } from '../ChatSettings/ChatSettingsContext';
import { QueryClient, QueryClientProvider } from 'react-query';

const GlobalContext: React.FC<ChildrenProps> = ({ children }) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 60 * 1000,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeContextProvider>
        <SettingsProvider>
          <PortalContextProvider>
            <LoadingContextProvider>
              <AuthenticationContextProvider>
                <PluginContextProvider>{children}</PluginContextProvider>
              </AuthenticationContextProvider>
            </LoadingContextProvider>
          </PortalContextProvider>
        </SettingsProvider>
      </ThemeContextProvider>
    </QueryClientProvider>
  );
};

export default GlobalContext;
