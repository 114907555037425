import styled from 'styled-components';

import { FlexColumn } from '../../App/Styles/Container.styled';
import { Image } from '../../App/Styles/Image.styled';
import { Link } from 'react-router-dom';
import { Paragraph } from '../../App/Styles/Typography.styled';
import { sizes } from '../../Utils/screenUtil';

export const Container = styled.div`
  grid-area: right;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  height: 92vh;
  padding: 0rem 1.5rem 1rem 0rem;
  @media only screen and (max-width: ${sizes.tablet}) {
    /* Styles for screens less than 768 pixels */
    display: none;
  }
`;

export const CenterContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem; /* Ensure consistent spacing between children */
  padding: 2rem;
  border-radius: 10px;
  min-height: 90%;
  max-height: 90%; /* Prevent overflowing on the parent level */
  background-color: ${({ theme }) => theme.sideMenu};
  border-top: 1px solid ${({ theme }) => `${theme.detailsText}30`};
  border-left: 1px solid ${({ theme }) => `${theme.detailsText}30`};
  border-right: 1px solid ${({ theme }) => `${theme.detailsText}30`};
  border-bottom: none;
  @media only screen and (max-width: 1321px) {
    /* Styles for screens less than 1321 pixels */
    min-width: 250px !important;
  }
  @media only screen and (min-width: 1321px) {
    /* Styles for screens less than 1321 pixels */
    min-width: 320px !important;
  }
  @media only screen and (min-width: 2240px) {
    /* Styles for screens more than 2240px pixels */
    width: 450px;
    transform: translateX(-130px);
  }
`;

export const PluginContainer = styled(Link)<{
  $enabled: boolean;
  $hasBorder?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  transition: ${({ theme }) => theme.transition};
  padding: 1rem 0.5rem;
  opacity: ${({ $enabled }) => ($enabled ? '1' : '0.5')};
  cursor: ${({ $enabled }) => ($enabled ? 'pointer' : 'default')};
  border-bottom: ${({ $hasBorder }) =>
    $hasBorder ? '1px solid rgba(178, 165, 165, 0.18)' : 'none'};
  border-radius: 0;

  &:hover {
    opacity: 0.5;
  }
`;

export const OnlineLamp = styled.div`
  padding: 0.3rem;
  border-radius: 50%;
  background-color: #5adda7;
`;

export const OfflineLamp = styled(OnlineLamp)`
  background-color: ${({ theme }) => theme.text};
`;

export const FlexColumnRightLayout = styled(FlexColumn)`
  height: 100%;
  justify-content: flex-start; /* Prevent stretching */
  gap: 1rem; /* Add a gap for spacing between elements */
`;

export const ColumnRightTitle = styled(Paragraph)`
  color: ${({ theme }) => theme.detailsText};
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.2; /* Adjust line height for better readability */
  @media (min-width: 1800px) {
    font-size: 1.3rem !important;
  }
  @media only screen and (min-width: 2240px) {
    /* Styles for screens more than 2240px pixels */
    font-size: 1.8rem !important;
    font-weight: bold;
  }
`;

export const ResponsiveImage = styled(Image)`
  @media (max-width: 768px) {
    margin: 0 auto;
  }
`;
