import { useContext } from 'react';
import { ChatSettingsContext } from './ChatSettingsContext';

// Custom hook for using SettingsContext
export const useChatSettings = () => {
  const chatSettingsContext = useContext(ChatSettingsContext);
  if (!chatSettingsContext) {
    throw new Error('useSettings must be used within a SettingsProvider');
  }
  return chatSettingsContext;
};
