import styled from 'styled-components';
import { useEffect, useState } from 'react';

import { US_STATES } from '../../../Other/constants';
import { ALL_TRUCK_TYPES } from '../../../Other/constants';
import { useChatSettings } from '../../../Context/ChatSettings/useChatSettings';
import { UserChatSettings } from '../../../Context/ChatSettings/ChatSettingsContext';
import { CircularProgress } from '@mui/material';
import { Title } from '../../../App/Styles/Typography.styled';
import { SettingsInputGroup } from './SettingsInputGroup';

export const SettingsForm = () => {
  const { settings, settingsIsLoading, updateSettings, fetchSettingsError } =
    useChatSettings();
  const [formValues, setFormValues] = useState<UserChatSettings>({
    origin_state: '',
    origin_city: '',
    equipment_type: '',
    companies: [],
    excluded_companies: [],
  });

  // Update form values when settings change
  useEffect(() => {
    if (settings) {
      setFormValues({
        origin_state: settings.origin_state || '',
        origin_city: settings.origin_city || '',
        equipment_type: settings.equipment_type || '',
        companies: settings.companies || [],
        excluded_companies: settings.excluded_companies || [],
      });
    }
  }, [settings]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    if (name === 'companies') {
      setFormValues((prevValues) => ({
        ...prevValues,
        companies: value.split(',').map((company) => company.trim()),
      }));
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const updatedFormValues = {
      ...formValues,
      excluded_companies:
        typeof formValues.excluded_companies === 'string'
          ? formValues.excluded_companies
              .split(',')
              .map((company) => company.trim())
              .filter((company) => company !== '') // Remove empty strings in array
          : formValues.excluded_companies, // If excluded_companies is already an array, keep it as-is
    };
    updateSettings(updatedFormValues);
  };

  return (
    <FormContainer onSubmit={handleSubmit}>
      {!!fetchSettingsError && (
        <ErrorSpan>Error fetching default Settings</ErrorSpan>
      )}
      <SubTitle>Origin:</SubTitle>
      <SettingsInputGroup
        label="State"
        name="origin_state"
        type="select"
        value={formValues.origin_state}
        options={US_STATES}
        handleInputChange={handleInputChange}
        isDisabled={true}
      />
      <SettingsInputGroup
        label="City"
        name="origin_city"
        type="text"
        value={formValues.origin_city}
        handleInputChange={handleInputChange}
        isDisabled={true}
      />
      <SubTitle>Truck:</SubTitle>
      <SettingsInputGroup
        label="Equipment Type"
        name="equipment_type"
        type="select"
        value={formValues.equipment_type}
        options={ALL_TRUCK_TYPES}
        handleInputChange={handleInputChange}
        isDisabled={false}
      />
      <SubTitle>Companies:</SubTitle>
      {/* <SettingsInputGroup
        label=""
        name="companies"
        type="text"
        value={formValues.companies.join(', ')}
        placeholder="Separate by commas"
        handleInputChange={handleInputChange}
      /> */}
      <SettingsInputGroup
        label="Excluded Companies"
        name="excluded_companies"
        type="text"
        value={formValues.excluded_companies}
        handleInputChange={handleInputChange}
        isDisabled={false}
      />
      {settingsIsLoading ? (
        <CircularProgress
          size={35}
          color="warning"
          sx={{ marginTop: '24px' }}
        />
      ) : (
        <Button type="submit">
          <ButtonSpan>Update Default</ButtonSpan>
        </Button>
      )}
    </FormContainer>
  );
};

const ErrorSpan = styled.span`
  color: red;
`;

const ButtonSpan = styled.span`
  color: ${({ theme }) => theme.buttonText};
`;

const SubTitle = styled(Title)`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 70%;
`;

const Button = styled.button`
  margin-top: 25px;
  width: 150px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.title};
  padding: 10px;
  cursor: pointer;
  border: none;

  &:hover {
    opacity: 0.8;
  }
`;
