export interface IScheduleItem {
  id: number;
  created_at: string;
  updated_at: string;
  prompt_text: string;
  status: string;
  email: string;
}

export type BoardName = 'truckstop' | '123loadboard' | 'manifold';

export enum Status {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Completed = 'COMPLETED',
  Expired = 'EXPIRED',
}
