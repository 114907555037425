import { useContext, useState } from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@mui/material';

import { H1Title, Paragraph } from '../../App/Styles/Typography.styled';
import {
  useGetPrompts,
  usePagination,
} from '../../Hooks/useApi/useSchedulerApi';
import { PromptCard } from '../../Components/PromptCard/PromptCard';
import { AddCircle } from '@styled-icons/material';
import { sizes } from '../../Utils/screenUtil';
import { AddPromptModal } from '../../Components/Modal/AddPromptModal';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import { sortPrompts } from '../../Utils/sortPrompts';
import { PluginContext } from '../../Context/Plugin/PluginContext';

const LoadSchedulerPage = () => {
  const { page, size } = usePagination();
  const { promptsResponseFlattened, promptsIsLoading } = useGetPrompts(
    page,
    size
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const pluginContext = useContext(PluginContext);

  const isConnectedToPlugin =
    pluginContext?.truckstopPlugin ||
    pluginContext!.LB123Plugin ||
    pluginContext!.manifoldPlugin;

  if (promptsIsLoading) {
    return (
      <ErrorCenteredContainer>
        <CircularProgress
          size={80}
          color="warning"
          sx={{ marginTop: '24px' }}
        />
      </ErrorCenteredContainer>
    );
  }

  const sortedPromptData = sortPrompts(promptsResponseFlattened);

  const promptsAvailable = sortedPromptData.length > 0;

  return (
    <SchedulerContainer>
      <SchedulerWrapper>
        <SchedulerHeader>
          <LeftHeader>
            <SchedulerTitle>LoadScout</SchedulerTitle>
            <StyledTooltip
              title="LoadScout enables users to schedule load searches and get alerts via email. "
              placement="top"
            >
              <InfoIcon />
            </StyledTooltip>
          </LeftHeader>
          <RightHeader>
            <AddIcon size={40} onClick={() => setIsModalOpen(true)} />
          </RightHeader>
        </SchedulerHeader>
        <SchedulerBody>
          {promptsAvailable ? (
            sortedPromptData.map((prompt, i) => (
              <PromptCard singlePrompt={prompt} key={i} />
            ))
          ) : (
            <NoDataFallback>
              {!isConnectedToPlugin
                ? 'Please connect to a provider'
                : 'no prompts scheduled...'}
            </NoDataFallback>
          )}
        </SchedulerBody>
      </SchedulerWrapper>
      <AddPromptModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
    </SchedulerContainer>
  );
};

export default LoadSchedulerPage;

const SchedulerContainer = styled.div`
  display: flex;
  height: 100%;
  margin-top: 2rem;
  width: 100%; // adjusts to the parent width of around 600px
  max-height: 80vh;
`;

const SchedulerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
`;

const SchedulerHeader = styled.div`
  margin-top: 30px;
  width: 100%;
  padding-left: 10px;
  padding: 10px;
  /* Ensure it dynamically adjusts to the h1's size */
  display: flex;
  align-items: center;
  justify-content: space-between;
  h1 {
    margin: 0; /* Remove default margins for the h1 to prevent extra spacing */
  }
`;

const SchedulerBody = styled.div`
  flex-grow: 1; /* This makes the body expand to fill remaining space */
  overflow-y: auto; /* Allow scrolling if content overflows */
  width: 100%;
`;

const ErrorCenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70%;
`;

const SchedulerTitle = styled(H1Title)`
  font-size: 80px;
  @media only screen and (max-width: ${sizes.desktopS}) {
    /* Styles for screens less than 1920px */
    font-size: 50px;
  }
  @media only screen and (max-width: ${sizes.laptopMid}) {
    /* Styles for screens less than 1250px */
    font-size: 40px;
  }
  @media only screen and (max-width: ${sizes.tablet}) {
    /* Styles for screens less than 768px */
    font-size: 35px;
  }
  @media only screen and (max-width: ${sizes.mobileL}) {
    /* Styles for screens less than 425px */
    font-size: 30px;
  }
`;

const AddIcon = styled(AddCircle)`
  margin-left: 20px;
  color: ${({ theme }) => theme.alternativeWhite};
  cursor: pointer;
  transform: translateY(2px);
  transition: transform 0.2s ease-in-out; /* Smooth transition effect */

  &:hover {
    transform: translateY(2px) scale(1.1); /* Slightly grow the icon */
    color: gray;
  }
`;

const StyledTooltip = styled(Tooltip)`
  font-size: 22px !important;
  transform: translate(5px, -10px);
  color: ${({ theme }) => theme.alternativeWhite};
`;

const LeftHeader = styled.div`
  display: flex;
`;

const RightHeader = styled.div``;

const NoDataFallback = styled(Paragraph)`
  margin-top: 100px;
  font-size: 25px;
  ${({ theme }) => theme.alternativeWhite};
  text-align: center;
`;
