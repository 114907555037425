import styled from 'styled-components';
import MuiModal from '@mui/material/Modal';
import { Close } from '@styled-icons/material';

import { useCreatePrompt } from '../../Hooks/useApi/useSchedulerApi';
import { useContext, useEffect, useState } from 'react';
import { PluginContext } from '../../Context/Plugin/PluginContext';
import { BoardName } from '../../Models/SchedulerModel';

interface AddPromptModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentPromptText?: string;
}

export const AddPromptModal: React.FC<AddPromptModalProps> = ({
  isOpen,
  setIsOpen,
  currentPromptText,
}) => {
  const [promptText, setPromptText] = useState<string>('');
  const { createPromptFn } = useCreatePrompt();
  const pluginContext = useContext(PluginContext);

  // Set promptText when currentPromptText changes or when the modal is opened
  useEffect(() => {
    if (isOpen) {
      setPromptText(currentPromptText || ''); // If currentPromptText exists, use it; otherwise, empty string
    }
  }, [isOpen, currentPromptText]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPromptText(e.target.value);
  };

  const truckStopIsActive = pluginContext?.truckstopPlugin;
  const lb123IsActive = pluginContext?.LB123Plugin;
  const manifoldIsActive = pluginContext?.manifoldPlugin;
  const noActiveLoadBoards = !(
    truckStopIsActive ||
    lb123IsActive ||
    manifoldIsActive
  );

  const handleScheduleClick = () => {
    if (promptText.trim()) {
      const boardNames: BoardName[] = [];
      if (truckStopIsActive) {
        boardNames.push('truckstop');
      }
      if (lb123IsActive) {
        boardNames.push('123loadboard');
      }
      if (manifoldIsActive) {
        boardNames.push('manifold');
      }

      createPromptFn({ promptText, boardNames });
      setIsOpen(false); // Close the modal after scheduling
    }
    setPromptText('');
  };

  return (
    <MuiModal open={isOpen} closeAfterTransition>
      <TransparentModal onClick={() => setIsOpen(false)}>
        <FormContainer
          onClick={(e) => e.stopPropagation()}
          onSubmit={handleScheduleClick}
        >
          <ModalHeader>
            <ModalTitle>Add Scheduled Prompt</ModalTitle>
            <CloseIcon size={35} onClick={() => setIsOpen(false)} />
          </ModalHeader>
          <FormBody>
            <Label>Prompt Text</Label>
            <PromptInput
              placeholder="Enter prompt text..."
              onChange={handleInputChange}
              value={promptText}
            />
            {noActiveLoadBoards && (
              <ProviderSpan>Please connect to a board provider</ProviderSpan>
            )}
            <ScheduleButton
              disabled={promptText.trim() === '' || noActiveLoadBoards}
              type="submit"
            >
              Schedule Prompt
            </ScheduleButton>
          </FormBody>
        </FormContainer>
      </TransparentModal>
    </MuiModal>
  );
};

const ProviderSpan = styled.span`
  font-size: 15px;
  color: #d15d5d;
  margin-bottom: 20px;
`;

const TransparentModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  outline: none;
`;

const FormContainer = styled.form`
  width: 400px;
  border-radius: 15px;
  padding: 20px;
  background-color: ${({ theme }) => theme.secondary};
  border: 1px solid ${({ theme }) => theme.settingsBorderOutlineColor};
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;

const ModalHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

const ModalTitle = styled.h2`
  color: ${({ theme }) => theme.title};
  font-size: 25px;
`;

const CloseIcon = styled(Close)`
  color: ${({ theme }) => theme.alternativeWhite};
  position: absolute;
  right: -10px;
  top: -10px;
  cursor: pointer;
`;

const FormBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const Label = styled.label`
  color: ${({ theme }) => theme.detailsText};
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;
`;

const PromptInput = styled.input`
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.settingsBorderColor};
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 20px;
  color: black;
  background-color: lightgray;
`;

const ScheduleButton = styled.button<{ disabled: boolean }>`
  padding: 10px;
  width: 150px;
  background-color: ${({ theme, disabled }) =>
    disabled ? 'gray' : theme.schedulerButtonBg};
  color: ${({ theme }) => theme.buttonText};
  border: none;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s ease;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  &:hover {
    background-color: ${({ theme, disabled }) =>
      disabled ? '' : theme.schedulerHoverBg};
  }
`;
