import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { useTypewriter } from 'react-simple-typewriter';

import { ILoad } from '../../Models/LoadModel';
import { Title } from '../../App/Styles/Typography.styled';
import { PortalContext } from '../../Context/Portal/PortalContext';
import { useGetUserProfile } from '../../Hooks/useApi/useAuthenticationApi';
import {
  generateEmailSubject,
  generateEmailBody,
} from '../../Utils/generateEmailTemplate';

interface EmailDraftModalProps {
  load: ILoad | null;
}

const EmailDraftModal: React.FC<EmailDraftModalProps> = ({ load }) => {
  const portalContext = useContext(PortalContext);
  const { userProfileData, userProfileDataIsLoading } = useGetUserProfile();

  const defaultSubject = generateEmailSubject(load, userProfileData || null);

  const [subject, setSubject] = useState(defaultSubject);
  const [textBody, setTextBody] = useState('');
  const [typingComplete, setTypingComplete] = useState(false);

  const [text] = useTypewriter({
    words: [generateEmailBody(load, userProfileData || null)],
    typeSpeed: 5,
    onLoopDone: () => setTypingComplete(true),
  });

  useEffect(() => {
    if (typingComplete) {
      setTextBody(generateEmailBody(load, userProfileData || null));
    }
  }, [typingComplete, load, userProfileData]);

  const handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubject(event.target.value);
  };

  const handleTextBodyChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setTextBody(event.target.value);
  };

  // Instead of returning early, conditionally render inside the JSX
  if (userProfileDataIsLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <Title bold={true}>Subject</Title>
      <Input type={'text'} value={subject} onChange={handleSubjectChange} />
      <Title bold={true}>Message</Title>
      <TextAreaContainer>
        <TextArea
          spellCheck={false}
          value={typingComplete ? textBody : text}
          onChange={handleTextBodyChange}
          readOnly={!typingComplete}
        />
      </TextAreaContainer>
      <div style={{ display: 'flex' }}>
        <Button
          onClick={() => portalContext?.setIsOpen(false)}
          href={`mailto:${
            load?.email ? load?.email : 'example@example.com'
          }?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
            textBody
          )}`}
        >
          <Title bold={true}>Send Email</Title>
        </Button>
        <Button onClick={() => portalContext?.setIsOpen(false)}>
          <Title bold={true}>Cancel</Title>
        </Button>
      </div>
    </Container>
  );
};

export default EmailDraftModal;

const Container = styled.div`
  padding: 20px;
  max-height: 85%;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.secondary};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;

  ${Title} {
    word-wrap: break-word;
    overflow: hidden;
  }
`;

const Input = styled.input`
  background-color: ${({ theme }) => theme.primary};
  border: none;
  height: 2.5rem;
  border-radius: 10px;
  color: ${({ theme }) => theme.text};
  padding: 0.5rem;

  &:focus {
    outline: 0;
  }
`;

const Button = styled.a`
  width: 100%;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.title};
  transition: ${({ theme }) => theme.transition};
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 10px;
  cursor: pointer;
  opacity: 0.8;

  ${Title} {
    color: ${({ theme }) => theme.primary};
  }

  &:last-of-type {
    margin-left: 10px;
    background-color: ${({ theme }) => theme.redBackground};
    transition: ${({ theme }) => theme.transition};
  }

  &:hover {
    opacity: 1;
  }
`;

const TextAreaContainer = styled.div`
  position: relative;
  width: 100%;
`;

const TextArea = styled.textarea`
  overflow: auto;
  resize: none;
  min-width: 500px;
  min-height: 350px;
  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.text};
  border: none;
  padding: 0.5rem;
  border-radius: 10px;
  z-index: 1;

  &:focus {
    outline: 0;
  }

  @media only screen and (max-width: 600px) {
    /* Styles for screens less than 600 pixels */
    min-width: 300px;
    max-width: 300px;
  }
`;
