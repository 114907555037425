import React, {
  useEffect,
  useRef,
  useCallback,
  useContext,
  useState,
} from 'react';
import { Fade } from 'react-awesome-reveal';
import styled from 'styled-components';

import { Paragraph, StyledDiv } from '../../App/Styles/Typography.styled';
import { ILoad } from '../../Models/LoadModel';
import TypePrompt from '../TypePrompt/TypePrompt';
import { PortalContext } from '../../Context/Portal/PortalContext';
import { AddPromptModal } from '../Modal/AddPromptModal';
import { LazyTable, TableMessageContainer } from './LazyTable';
import { CollapsibleTable } from '../Table/CollapsibleTable';

export type MessageItem = {
  isUser: boolean;
  title?: string;
  loadList?: ILoad[];
  userPrompt?: string;
  promptTruckType?: boolean;
  overallPromptNum: number;
  isRelaxed?: boolean;
  isCachedData?: boolean;
};

type MessageRecyclerProps = {
  messages: MessageItem[];
  handleSelect: (value: string) => void;
  sendNewMessage: (query?: string) => Promise<void>;
};

const MessageRecycler: React.FC<MessageRecyclerProps> = ({
  messages,
  handleSelect,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentPromptText, setCurrentPromptText] = useState<string>('');
  const containerRef = useRef<HTMLDivElement | null>(null);
  const messageRefs = useRef<(HTMLDivElement | null)[]>([]);
  const portalContext = useContext(PortalContext);

  const scrollToMessage = useCallback((index: number) => {
    const messageElement = messageRefs.current[index];
    if (messageElement && containerRef.current) {
      const offsetTop = messageElement.offsetTop;
      containerRef.current.scrollTo({
        top: offsetTop - 200,
        behavior: 'smooth',
      });
    }
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      scrollToMessage(messages.length - 1);
    }
  }, [messages.length, scrollToMessage]);

  useEffect(() => {
    if (messages.length > 0) {
      scrollToMessage(messages.length - 1);
    }
  }, [messages, scrollToMessage]);

  useEffect(() => {
    const handleGlobalScroll = (event: WheelEvent) => {
      if (containerRef.current) {
        const delta = event.deltaY || event.detail;
        containerRef.current.scrollTop += delta;
        event.preventDefault();
      }
    };

    window.addEventListener('wheel', handleGlobalScroll, { passive: false });

    return () => {
      window.removeEventListener('wheel', handleGlobalScroll);
    };
  }, []);

  return (
    <Container ref={containerRef}>
      {messages.map((item, index) => {
        const isUser = item.isUser;
        const tableShouldAppear = item.loadList && item.loadList.length > 0;
        const typePromptShouldAppear =
          item.promptTruckType && messages.length - 1 === index;

        const lastMessages = index >= messages.length - 5;

        return (
          <MessageRow
            shouldOverflow={portalContext?.isOpen}
            isUser={isUser}
            key={index}
            ref={(el) => (messageRefs.current[index] = el)}
          >
            {item.title && !item.promptTruckType && (
              <QueryContainer isUser={isUser}>
                {isUser ? (
                  <Paragraph>{item.title}</Paragraph>
                ) : (
                  <Paragraph as="div" className="fade-container">
                    <Fade duration={item.isCachedData ? 0 : 400} triggerOnce>
                      <StyledDiv>
                        {item.userPrompt && item.overallPromptNum !== 1 && (
                          <PromptDiv>
                            <StyledLine>Your prompt:</StyledLine>
                            <StyledPrompt>{item.userPrompt}</StyledPrompt>
                          </PromptDiv>
                        )}
                        <StyledTitleLine>{item.title}</StyledTitleLine>
                        {item.isRelaxed && (
                          <>
                            <RelaxedText>
                              If you need to schedule a load please click{' '}
                            </RelaxedText>
                            <RelaxedSpanButton
                              onClick={() => {
                                setCurrentPromptText(item.userPrompt || '');
                                setIsModalOpen(true);
                              }}
                            >
                              here
                            </RelaxedSpanButton>
                          </>
                        )}
                      </StyledDiv>
                    </Fade>
                  </Paragraph>
                )}
              </QueryContainer>
            )}

            {tableShouldAppear && (
              <>
                {lastMessages ? (
                  // For the last messages, render the table normally
                  <Fade
                    duration={item.isCachedData ? 0 : 800}
                    delay={item.isCachedData ? 0 : 400}
                    triggerOnce
                    style={{ minWidth: '100%' }}
                  >
                    <TableMessageContainer>
                      <CollapsibleTable loadList={item.loadList || []} />
                    </TableMessageContainer>
                  </Fade>
                ) : (
                  // For all previous, render the table lazily
                  <Fade
                    duration={item.isCachedData ? 0 : 800}
                    delay={item.isCachedData ? 0 : 400}
                    triggerOnce
                    style={{ minWidth: '100%' }}
                  >
                    <LazyTable loadList={item.loadList || []} />
                  </Fade>
                )}
              </>
            )}

            {typePromptShouldAppear && (
              <Fade
                triggerOnce
                duration={item.isCachedData ? 0 : 800}
                delay={item.isCachedData ? 0 : 400}
              >
                <TruckPromptContainer isUser={isUser}>
                  <TypePrompt
                    handleSelect={handleSelect}
                    handleToggle={() => scrollToMessage(messages.length - 1)}
                  />
                </TruckPromptContainer>
              </Fade>
            )}
          </MessageRow>
        );
      })}
      <AddPromptModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        currentPromptText={currentPromptText}
      />
    </Container>
  );
};

export default MessageRecycler;

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  grid-row: 1 / span 7;
`;

//The issue with the old Container for scrolls
// const Container = styled.div`
//   width: 100%;
//   height: 100%;
//   overflow-y: hidden; /* Vertical scrollbar off */
//   overflow-x: scroll; /* Horizontal scrollbar on */
//   gap: 2rem;
//   display: flex;
//   flex-direction: column;
//   grid-row: 1 / span 7;

//   /* Webkit Browsers */
//   ::-webkit-scrollbar {
//     height: 10px; /* Thicker scrollbar */
//   }

//   ::-webkit-scrollbar-thumb {
//     background: rgba(203, 203, 203, 0.77); /* Thumb color */
//     border-radius: 6px; /* Rounded corners for the thumb */
//   }

//   ::-webkit-scrollbar-track {
//     /* Track is the background unselected part */
//     display: none;
//   }

//   /* Firefox Only */
//   @-moz-document url-prefix() {
//     scrollbar-color: rgba(119, 119, 119, 0.9) rgba(0, 0, 0, 0.1); /* Thumb color Track color */
//     scrollbar-width: auto; /* Thick scrollbar for Firefox */
//   }

//   @media only screen and (max-width: 490px) {
//     /* Styles for screens less than 490px */
//     scrollbar-width: none; /* Hides scrollbar for Firefox */
//     ::-webkit-scrollbar {
//       display: none; /* Hides scrollbar for Webkit browsers */
//     }
//   }
// `;

const RelaxedSpanButton = styled.span`
  color: ${({ theme }) => theme.title};
  font-weight: bold;
  :hover {
    cursor: pointer;
  }
`;

const RelaxedText = styled.span``;

const PromptDiv = styled.div`
  margin-bottom: 7px;
`;

const StyledTitleLine = styled.span`
  display: block;
  margin-bottom: 8px;
`;
const StyledLine = styled.span`
  margin-bottom: 2px;
  font-style: italic;
`;
const StyledPrompt = styled.span`
  font-weight: bold;
  font-style: italic;
  margin-left: 10px;
  color: ${({ theme }) =>
    theme.promptText}; // You can select here the prompt text color
`;

export const MessageRow = styled.div<{
  isUser: boolean;
  shouldOverflow: boolean | undefined;
}>`
  display: flex;
  flex-direction: column;

  /*  overflow-y allows the background to stop being scrollable when modal is Open */
  overflow-y: ${({ shouldOverflow }) => (shouldOverflow ? 'hidden' : 'none')};

  align-items: ${({ isUser }) => (isUser ? 'end' : 'start')};
  gap: 10px;
  width: 100%;
  border-radius: 10px;
`;

const QueryContainer = styled.div<{ isUser?: boolean | undefined }>`
  padding: 0.75rem;
  background-color: ${(props) => props.theme.secondary};
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  border-radius: ${(props) =>
    props.isUser ? '0.5rem 0px 0.5rem 0.5rem' : '0 0.5rem 0.5rem 0.5rem'};
  margin: 0;

  .fade-container {
    color: ${(props) => props.theme.text};
  }
`;

const TruckPromptContainer = styled(QueryContainer)`
  flex-direction: row;
  position: relative;
`;
